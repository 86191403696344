<template>
  <div class="d-flex validate-address">
    <b-form @submit.prevent="searchStore">
      <b-form-input
        type="text"
        placeholder="Enter Store Name"
        v-model="store"
        :class="validityLevel"
        required
      />
      <div class="d-flex justify-content-between">
        <span class="message">{{ message }}</span>
        <button
          class="verify-button"
          :class="processingRequest"
          type="submit"
          ref="storeSubmittable"
        >
          Find
          <img
            class="ajax-loader"
            src="/media/ajax/white.gif"
            alt="Ajax loader"
          />
        </button>
      </div>
    </b-form>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";
export default {
  name: "StoreNameSearch",
  props: {
    storePassed: String,
  },
  data() {
    return {
      store: "",
      processingRequest: "",
      message: "",
      validityLevel: "",
    };
  },
  methods: {
    searchStore: async function () {
      this.processingRequest = "processing";
      await ApiService.get(`/store/searchStore/${this.store}`)
        .then(({ data }) => {
          if (data.store !== -1) {
            this.message = "Please retry if no store!";
            const stores = data.store.map((store) => ({
              storeAddress: store.storeAddress.address,
              storeName: store.storeName,
              storeId: store._id,
            }));
            this.validityLevel = "border-success";
            this.$emit("sendStore", stores);
          } else {
            this.$emit("sendStore", null);
            this.message = "Store Not Found. Try Again!";
            this.validityLevel = "border-danger";
          }
        })
        .catch(() => {
          this.$emit("error", "Can't confirm store Name, please try again.");
        });
      this.processingRequest = "";
    },
  },
  mounted() {
    this.store = this.storePassed;
  },
};
</script>

<style lang="scss" scoped>
.validate-address {
  form {
    width: 100%;
    .verify-button {
      color: #fff;
      background: #dc1828;
      padding: 2px 10px;
      border-radius: 25px;
      display: flex;
      align-items: center;
      font-size: 12px;
      &:focus,
      &:hover {
        background: #dc1828;
        color: #fff;
      }
      &.processing {
        .ajax-loader {
          display: block;
        }
      }
      .ajax-loader {
        margin-left: 10px;
        height: 10px;
        width: auto;
        display: none;
      }
    }
    .message,
    .address {
      font-size: 10px;
    }
  }
}
</style>
