<template>
  <div class="upload-products">
    <div class="row">
      <h3>Upload Products</h3>
    </div>
    <template v-if="message !== ''">
      <div class="row">
        <div class="col-12 text-warning">{{ message }}</div>
      </div>
    </template>
    <div class="row d-flex align-items-center justify-content-center">
      <b-form @submit.prevent="findStore" class="w-100">
        <b-row class="my-1 w-100">
          <b-col cols="3" class="place-center">
            <label for="csvFile">Find a store </label>
          </b-col>
          <b-col cols="6" class="place-center">
            <b-row>
              <b-col cols="6">
                <b-form-input
                  type="text"
                  placeholder="Look for a store to add products aginst (Leave empty in case of grocery)"
                  name="name"
                  id="store-name"
                  class="mt-3"
                  required
                  v-model="searchStoreText"
                />
              </b-col>
              <b-col cols="6">
                <b-form-input
                  type="number"
                  placeholder="zipCode"
                  name="name"
                  id="zip-code"
                  class="mt-3"
                  v-model="zipCode"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="3" class="place-center">
            <button
              type="submit"
              variant="danger"
              :disabled="fetchingStore === 'fetching'"
            >
              Find
            </button>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <template v-if="stores.length > 0">
      <b-row>
        <b-col cols="12">
          <b-form-select
            v-model="storeId"
            :options="stores"
            @change="storeChanged"
          />
        </b-col>
      </b-row>
    </template>
    <template v-if="storeFound !== null && fetchingStore !== 'fetching'">
      <template v-if="storeFound === -1">
        The store was not found against the query. Please try again.
      </template>
      <template v-else>
        <div class="row found-store-row">
          <div class="col-12"><b>Store:</b> {{ storeFound.text }}</div>
        </div>
        <div class="row found-store-row">
          <div class="col-12">
            <b>Address: </b>{{ storeFound.storeAddress.address }}
          </div>
        </div>
        <div class="row found-store-row">
          <div class="col-6">
            <b>Store Type: </b>
          </div>
          <div class="col-6">
            <b-form-select v-model="storeType" :options="storeTypeOptions">
            </b-form-select>
          </div>
        </div>
      </template>
    </template>
    <div class="row d-flex align-items-center justify-content-center">
      <div class="col-12" style="text-align: center">
        <b>CSV needs to have these columns. *required</b><br />
        {{ csvFormat }}
      </div>
      <b-form @submit.prevent="createProducts" class="w-100">
        <b-row class="my-1 w-100">
          <b-col cols="3" class="place-center">
            <label for="csvFile">Upload the CSV File</label>
          </b-col>
          <b-col cols="6" class="place-center">
            <input
              @change="uploadFile"
              type="file"
              name="csvFile"
              id="rider-iamge-input"
              class="mt-3"
              ref="csvFileRef"
              accept=".csv"
              required
            />
          </b-col>
          <b-col cols="3" class="place-center">
            <button
              type="submit"
              variant="danger"
              :disabled="fetching === 'fetching'"
            >
              Upload
            </button>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <b-overlay :show="fetching === 'fetching'">
      <template #overlay>
        <div class="w-100 h-100" style="display: grid; place-items: center">
          <img
            src="/media/ajax/white.gif"
            alt="Ajax loader"
            style="height: 40px; width: 40px"
          />
          <span>Creating Products</span>
        </div>
      </template>
    </b-overlay>
    <div class="products-created">
      <template v-if="fetching === 'fetched' && products.length > 0">
        <div class="row">
          <div class="col-12">
            <ul class="products-list">
              <li
                class="product-item"
                v-for="(product, index) in products"
                :key="index"
              >
                {{ product.name }}
              </li>
            </ul>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import { mapState } from "vuex";
export default {
  data() {
    return {
      csvFile: null,
      fetching: "not-fetched",
      products: [],
      message: "",
      searchStoreText: "",
      zipCode: "",
      storeFound: null,
      csvFormat:
        "name* | category | subCategory* | businessLine* | store | price | unit | squad(yes|no)",
      storeTypeOptions: ["Grocery", "Food"],
      fetchingStore: "not-fetched",
      storeType: "",
      stores: [],
      storeId: null,
    };
  },
  methods: {
    uploadFile() {
      this.csvFile = this.$refs.csvFileRef.files[0];
    },
    storeChanged(val) {
      if (val !== null) {
        this.storeFound = this.stores.find((s) => s.value === val);
        if (this.storeFound === -1) {
          this.storeType = this.storeFound.storeType;
          this.csvFormat =
            "name* | category | subCategory* | businessLine* | store | price | unit | squad(yes|no)";
        } else {
          if (this.storeFound.storeType.toLowerCase() !== "food") {
            this.message =
              "This store is not of type Food. It will have no effect on the products.";
          } else {
            this.csvFormat = "name* | category | subCategory*";
          }
        }
      }
    },
    findStore: function () {
      this.fetchingStore = "fetching";
      this.message = "";
      this.storeFound = null;
      this.storeId = null;
      ApiService.query(
        `/store/searchStore/${this.searchStoreText}${
          this.zipCode ? `?zipCode=${this.zipCode}` : ""
        }`
      )
        .then(({ data }) => {
          this.fetchingStore = "fetched";
          this.stores = data.store.map((item) => ({
            text: item.storeName,
            storeType: item.storeType,
            value: item._id,
            storeAddress: item.storeAddress,
          }));
          this.stores.push({
            text: "Select a store.",
            value: null,
            storeType: null,
            storeAddress: null,
          });
          this.message = data.message;
        })
        .catch((error) => {
          this.fetchingStore = "fetched";
          this.stores = [
            {
              value: null,
              storeType: null,
              text: error.message,
              storeAddress: null,
            },
          ];
          this.message = error.message;
          this.csvFormat =
            "name* | category | subCategory* | businessLine* | store | price | unit | squad(yes|no)";
        });
    },
    createProducts: function () {
      this.fetching = "fetching";
      this.message = "";
      const token = JwtService.getToken();
      const formData = new FormData();
      formData.append("token", token);
      if (this.storeFound !== null && this.storeFound !== -1) {
        formData.append("storeId", this.storeFound.value);
        if (this.storeType !== this.storeFound.storeType) {
          formData.append("changeStoreType", this.storeType);
        }
      }
      formData.append("myFile", this.csvFile);
      ApiService.post("/products/addProductsFromCsv", formData)
        .then(({ data: response }) => {
          this.fetching = "fetched";
          let data = null;
          if (response.success) {
            data = response;
          } else {
            data = response.error;
          }
          this.message = data.message;
          this.products = data.products;
          ApiService.setHeader("content-type", "application/json");
        })
        .catch((error) => {
          this.fetching = "fetched";
          this.message = error.message;
          ApiService.setHeader("content-type", "application/json");
        });
    },
  },
  computed: {
    ...mapState({
      region: (state) => state.region.isRegion,
    }),
  },
};
</script>
<style lang="scss" scoped>
.upload-products {
  box-sizing: border-box;
  .products-created {
    min-height: 100px;
    .products-list {
      list-style: none;
      li {
        background: #fff;
        border-radius: 5px;
        margin-bottom: 5px;
        padding: 5px;
      }
    }
  }
  .found-store-row {
    background: #fff;
    border-radius: 5px;
    margin-bottom: 5px;
    padding: 5px;
  }
  .place-center {
    display: grid;
    place-items: center;
  }
}
</style>
