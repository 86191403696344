<template>
  <div>
    <template v-if="region() === 'PAKISTAN'">
      <upload-products-pk />
    </template>
    <template v-else>
      <upload-products-us />
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UploadProductsPk from "@/view/pages/products/pk/UploadProducts";
import UploadProductsUs from "@/view/pages/products/us/UploadProducts";
export default {
  mounted() {
    const x = this.region();
    console.log("file: UploadProducts.vue | line 19 | mounted | x", x);
  },
  name: "UploadProductsMain",
  components: {
    UploadProductsPk,
    UploadProductsUs,
  },
  methods: {
    ...mapGetters(["region"]),
  },
};
</script>

<style lang="scss" scoped></style>
